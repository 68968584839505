<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="rules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">{{ appTitle }}</h3>
      </div>
      <el-form-item prop="email">
        <span class="svg-container">
          <i class="el-icon-user"></i>
        </span>
        <el-input
          v-model="loginForm.email"
          placeholder="Địa chỉ Email"
          :disabled="loading"
          type="text"
          tabindex="1"
        />
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="Đang bật CAPS LOCK"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-lock"></i>
          </span>
          <el-input
            v-model="loginForm.password"
            type="password"
            show-password
            placeholder="Mật khẩu"
            :disabled="loading"
            tabindex="2"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
      </el-tooltip>
      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
      >Đăng nhập</el-button>
    </el-form>
  </div>
</template>

<script>
import { login, updateLoginInfo } from '@/services/auth'
import CONSTANTS from '@/config/constants'
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      rules: {
        email: [{ required: true, message: ' ' }],
        password: [{ required: true, message: ' ' }]
      },
      loading: false,
      capsTooltip: false,
      appTitle: CONSTANTS.APP_TITLE
    }
  },
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },

    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          login(this.loginForm)
            .then((response) => {
              updateLoginInfo(response)
              this.$notify({
                title: 'Thành công',
                message: 'Đăng nhập thành công',
                type: 'success'
              })
              this.loading = false
              if (response.auth_user.staff_type === 'cook') {
                this.$router.push({ name: 'ManageCook' })
              } else if (response.auth_user.staff_type === 'cashier') {
                this.$router.push({ name: 'ManageCashier' })
              } else {
                window.location.reload()
              }
            })
            .catch(() => {
              this.$message.error(
                'Địa chỉ Email hoặc mật khẩu không chính xác.'
              )
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
$bg: #580003fc;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}
/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>
<style lang="scss" scoped>
@import "./style.scss";
</style>
